import React, { ReactNode } from 'react'
import { GlobalStyles } from 'twin.macro'
import { ThemeProvider } from '@emotion/react'
import defaultTheme from 'tailwindcss/defaultTheme'
import { Global, css } from '@emotion/react'

const theme = {
  breakpoints: {
    ...defaultTheme.screens,
    '3xl': '1900px',
    '4xl': '2200px',
  },
}

const styles = css`
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: Rubik;
  }

  *:focus,
  button:focus {
    outline: none !important;
  }

  a#CybotCookiebotDialogPoweredbyCybot {
    display: none;
  }
`

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children, ...rest }: LayoutProps) => (
  <div {...rest}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Global styles={styles} />
      {children}
    </ThemeProvider>
  </div>
)

export default Layout

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-articles-how-is-a-special-purpose-depository-institution-compared-to-a-trust-company-tsx": () => import("./../../../src/pages/articles/how-is-a-special-purpose-depository-institution-compared-to-a-trust-company.tsx" /* webpackChunkName: "component---src-pages-articles-how-is-a-special-purpose-depository-institution-compared-to-a-trust-company-tsx" */),
  "component---src-pages-articles-our-history-and-the-importance-of-spdi-wyoming-bank-charter-law-tsx": () => import("./../../../src/pages/articles/our-history-and-the-importance-of-spdi-wyoming-bank-charter-law.tsx" /* webpackChunkName: "component---src-pages-articles-our-history-and-the-importance-of-spdi-wyoming-bank-charter-law-tsx" */),
  "component---src-pages-articles-rule-15-c-3-3-qualified-custody-of-securities-and-its-importance-in-secondary-market-trading-tsx": () => import("./../../../src/pages/articles/rule-15c3-3-qualified-custody-of-securities-and-its-importance-in-secondary-market-trading.tsx" /* webpackChunkName: "component---src-pages-articles-rule-15-c-3-3-qualified-custody-of-securities-and-its-importance-in-secondary-market-trading-tsx" */),
  "component---src-pages-articles-understanding-controllable-electronic-records-cers-and-the-proposed-uniform-commercial-code-revisions-tsx": () => import("./../../../src/pages/articles/understanding-controllable-electronic-records-cers-and-the-proposed-uniform-commercial-code-revisions.tsx" /* webpackChunkName: "component---src-pages-articles-understanding-controllable-electronic-records-cers-and-the-proposed-uniform-commercial-code-revisions-tsx" */),
  "component---src-pages-ceo-message-tsx": () => import("./../../../src/pages/ceo-message.tsx" /* webpackChunkName: "component---src-pages-ceo-message-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

